.form-group, .btn {
    margin: 1%;
}
.form-group {
    margin-top: 1.75%;
    margin-bottom: 1.75%;
    font-weight: 600;
}
.message {
    font-size: 1.2em;
    text-align: center;
    color: #ff0000;
}
.loggedOut {
    font-size: 1.2em;
    color: #ff0000;
    text-align: center;
    margin-top: 2%;
}